<template>
  <div style="margin: -14px -15px;overflow: hidden">
    <!--    病例审核管理-->
    <!--    <div class="review-title">病例审核管理</div>-->
    <!--    医生信息-->
    <div class="review-doc">
      <div class="review-doc-title">医生信息</div>
      <div class="review-doc-content">
        <div class="review-doc-content-font">姓名 : {{dataForm.memberName}}</div>
        <div class="review-doc-content-font">医院 : {{dataForm.memberHospitalName}}</div>
        <div class="review-doc-content-font">上传时间 : {{dataForm.created_time}}</div>
      </div>
    </div>
    <!--    患者建档信息-->
    <div class="review-huan">
      <div class="review-huan-title">
        <div class="review-huan-title-huanzhe">患者建档信息</div>
        <div>
          <a-button type="primary" @click="preservation">保存患者信息</a-button>
        </div>
      </div>
      <div class="review-huan-content">
        <div class="review-huan-content-font">
          <div>性别 : </div>
          <div style="margin-left: 15px">
            <a-radio-group
                v-model="dataForm.patientSex"
                :options="SexPerson"
                @change="onChange" />
          </div>
        </div>
        <div class="review-huan-content-font-two">
          <!--          <div>年龄 :</div><div> {{caseDetail.patientAge}}</div>-->
          <div>年龄 :{{dataForm.patientAge}}</div>
          <div style="margin-left: 5px">年龄</div>
          <div style="margin-left: 15px">
            <a-date-picker
                placeholder="请选择性出生日期"
                :format="dateFormatList"
                @change="ageChange" /></div>
        </div>
      </div>
    </div>
    <!--    中间上传照片部分-->
    <div class="review-content">
      <!--      图片信息-->
      <div class="review-content-left">
        <div class="review-content-left-title">图片信息
          <a-button
              @click="refreshBtn"
              type="dashed" style="margin-left: 50%">刷新</a-button>
          <a-button
              @click="captchaBtn"
              type="primary" style="margin-left: 20px">打码</a-button>
        </div>
        <div class="review-content-left-font" style="margin-top: 30px">病例图片 ({{lenghtImage}}张)</div>
        <div style="height:1500px;width:100%;overflow:auto;">
          <div
              style="width: 100%"
              v-for="(item,index) in  dataForm.coding_image_list"
          >
            <img
                style="width: 95%;height: auto;margin: 10px 10px"
                :key="index"
                :src="dataForm.coding_image_list[index]"
            />
          </div>
        </div>
      </div>
      <!--      问卷信息-->
      <div class="review-content-right">
        <div class="review-content-right-title">问卷信息</div>
        <div style="width: 80%" v-show="dataForm.reject_reason">
          <div class="review-content-right-title-title">驳回原因</div>
          <div class="review-content-right-person" style="margin-top: 15px">
            {{dataForm.reject_reason}}
          </div>
        </div>
        <div style="width: 80%">
          <div class="review-content-right-title-title">患者基本信息</div>
          <div class="review-content-right-person" style="margin-top: 15px">
            <div class="review-content-right-person-sex">
              住院编号 : <a-input
                class="inputWitch"
                v-model="dataForm.template_content.resident_admit_note.patient_number"
            />
            </div>
            <a-button style="margin-right: 10px" @click="examineBtn(dataForm.id,dataForm.template_content.resident_admit_note.patient_number)" type="primary">检查</a-button>
            <div class="review-content-right-person-sex">
              性别 : <a-radio-group
                v-model="dataForm.template_content.resident_admit_note.sex"
                :options="questSex" />
            </div>
          </div>
          <div class="review-content-right-person" style="margin-top: 15px">
            <div class="review-content-right-person-name">
              年龄 : <a-input
                class="inputWitch"
                v-model="dataForm.template_content.resident_admit_note.age"
            />
            </div>
            <div style="margin-left: -30px">
              患者体重 : <a-input
                class="inputWitch"
                v-model="dataForm.template_content.resident_admit_note.height"
                placeholder="请输入体重"
            />
            </div>
          </div>
          <div style="margin-top: 15px;margin-left:85px;">
            <div style="display: flex">
              <div style="width: 90px">是否转诊 :
              </div>
              <a-radio-group
                  v-model="dataForm.template_content.resident_admit_note.is_referral"
                :options="questAnotherHospital"
              />
              <a-input
                  v-show="dataForm.template_content.resident_admit_note.is_referral === 1"
                  v-model="dataForm.template_content.resident_admit_note.office"
                  style="width: 200px"
                  placeholder="请输入科室"
              />
            </div>
          </div>
          <div class="review-content-right-person" style="margin-top: 15px">
            入院时间 :
            <a-date-picker
                :allowClear="false"
                v-model="dataForm.template_content.resident_admit_note.admission_time"
                style="width: 300px;margin-left: 10px"
                placeholder="Select month"
                @change="hospitalizedChange" />
          </div>
          <div class="review-content-right-person">
            <div style="margin-left: 3%">既往史 :</div>
            <div style="width: 78%;margin-left: 5px">
              <a-textarea
                  v-model="dataForm.template_content.resident_admit_note.previous_hostory"
                  style="width: 100%"
                  placeholder=""
                  :auto-size="{ minRows: 3, maxRows: 5 }"
              />
            </div>
          </div>
        </div>
        <div style="display: flex;width: 100%;margin-top: 20px">
          <div style="margin-left: 30px;display: flex">
            是否有高血压病史 :<a-radio-group
              style="margin-left: 20px"
              v-model="dataForm.template_content.resident_admit_note.hypertension_hostory"
              :options="questBlood" />
          </div>
        </div>
        <div style="width: 100%;margin-top: 20px">
          <div style="margin-left: 50px;">
            <div>入院诊断 :</div>
            <a-checkbox-group
                v-model="dataForm.template_content.resident_admit_note.diagnosis_admission"
                style="width: 350px;margin-left: 80px"
                @change="checkboxChange">
              <a-row>
                <a-col :span="12" style="margin-top: 10px;">
                  <a-checkbox value="DebakeyⅠ型">
                    DebakeyⅠ型
                  </a-checkbox>
                </a-col>
                <a-col :span="12" style="margin-top: 10px;">
                  <a-checkbox value="Stanford A型">
                    Stanford A型
                  </a-checkbox>
                </a-col>
                <a-col :span="12" style="margin-top: 10px;">
                  <a-checkbox value="DebakeyⅡ型">
                    DebakeyⅡ型
                  </a-checkbox>
                </a-col>
                <a-col :span="12" style="margin-top: 10px;">
                  <a-checkbox value="Stanford B型">
                    Stanford B型
                  </a-checkbox>
                </a-col>
                <a-col :span="12" style="margin-top: 10px;">
                  <a-checkbox value="DebakeyⅢ型 ">
                    DebakeyⅢ型
                  </a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
          </div>
        </div>
        <div style="display: flex;margin-top: 20px">
          <div style="margin-left: 50px">其他诊断结果 :</div>
          <div style="width: 50%;margin-left: 5px">
            <a-textarea
                v-model="dataForm.template_content.resident_admit_note.other_admission"
                style="width: 100%"
                placeholder=""
                :auto-size="{ minRows: 3, maxRows: 5 }"
            />
          </div>
        </div>
        <div style="display: flex;margin-top: 20px">
          <div style="margin-left: 80px">症状体征 :</div>
          <div style="width: 50%;margin-left: 5px">
            <a-textarea
                v-model="dataForm.template_content.resident_admit_note.sympotms_signs"
                style="width: 100%"
                placeholder=""
                :auto-size="{ minRows: 3, maxRows: 5 }"
            />
          </div>
        </div>
        <div style="display: flex;width: 100%;margin-top: 20px">
          <div style="margin-left: 30px;display: flex">
            是否胸腹或背部疼痛 :<a-radio-group
              style="margin-left: 20px"
              v-model="dataForm.template_content.resident_admit_note.is_chest_abdomen_back_pain"
              :options="questChest" />
          </div>
        </div>
        <div style="width: 100%;margin-top: 20px;margin-left: 70px">
          <div style="display: flex;margin-top: 10px">
            <div style="width: 100px;text-align: right">心率HR(次/分)</div>
            <div style="margin-left: 10px">
              <a-input
                  v-model="dataForm.template_content.resident_admit_note.hr"
                  style="width: 300px"
                  placeholder="请输入初始检查结果"
              />
            </div>
          </div>
          <div style="display: flex;margin-top: 10px">
            <div style="width: 100px;text-align: right">血压BP(mmHg)</div>
            <div style="margin-left: 10px">
              <a-input
                  v-model="dataForm.template_content.resident_admit_note.bp"
                  style="width: 300px"
                  placeholder="请输入初始检查结果"
              />
            </div>
          </div>
          <div style="display: flex;margin-top: 10px">
            <div style="width: 100px;text-align: right">体温T(℃)</div>
            <div style="margin-left: 10px">
              <a-input
                  v-model="dataForm.template_content.resident_admit_note.temperature"
                  style="width: 300px"
                  placeholder="请输入初始检查结果"
              />
            </div>
          </div>
          <div style="display: flex;margin-top: 10px">
            <div style="width: 100px;text-align: right">脉搏P（次/分</div>
            <div style="margin-left: 10px">
              <a-input
                  v-model="dataForm.template_content.resident_admit_note.pulse"
                  style="width: 300px"
                  placeholder="请输入初始检查结果"
              />
            </div>
          </div>
          <div style="display: flex;margin-top: 10px">
            <div style="width: 100px;text-align: right">呼吸R（次/分</div>
            <div style="margin-left: 10px">
              <a-input
                  v-model="dataForm.template_content.resident_admit_note.breathe"
                  style="width: 300px"
                  placeholder="请输入初始检查结果"
              />
            </div>
          </div>
          <div style="display: flex;margin-top: 10px">
            <div style="width: 100px;text-align: right">是否手术:</div>
            <div style="margin-left: 10px">
              <a-radio-group
                  v-model="dataForm.template_content.resident_admit_note.is_operation"
                  :options="questOPS" />
            </div>
          </div>
          <div style="display: flex;margin-top: 10px">
            <div style="width: 100px;text-align: right">手术时间:</div>
            <div style="margin-left: 10px">
              <a-date-picker
                  :allowClear="false"
                  v-model="dataForm.template_content.resident_admit_note.operation_time"
                  style="width: 300px;margin-left: 10px"
                  placeholder="Select month"
                  @change="operationChange" />
            </div>
          </div>
        </div>


        <div style="display: flex;width: 100%;margin-top: 20px">
          <div style="margin-left: 30px;display: flex">
            医嘱用药信息
          </div>
        </div>
        <div style="width: 100%;margin-top: 20px;margin-left: 70px">
          <div style="display: flex;margin-top: 10px">
            <div style="width: 100px;text-align: right">使用时间:</div>
            <div style="margin-left: 10px">
              <a-date-picker
                  :allowClear="false"
                  v-model="dataForm.template_content.prescribed_medication.start_time"
                  style="width: 300px;margin-left: 10px"
                  placeholder="Select month"
                  @change="startChange" />
            </div>
          </div>
          <div style="display: flex;margin-top: 10px">
            <div style="width: 100px;text-align: right">结束时间:</div>
            <div style="margin-left: 10px">
              <a-date-picker
                  :allowClear="false"
                  v-model="dataForm.template_content.prescribed_medication.end_time"
                  style="width: 300px;margin-left: 10px"
                  placeholder="Select month"
                  @change="endChange" />
            </div>
          </div>
          <div style="display: flex;margin-top: 10px">
            <div style="width: 100px;text-align: right">使用方法:</div>
            <div style="margin-left: 10px">
              <a-radio-group
                  v-model="dataForm.template_content.prescribed_medication.method"
                  :options="questuse" />
            </div>
          </div>
          <div style="display: flex;margin-top: 10px">
            <div style="width: 120px;text-align: right">是否使用负荷剂量:</div>
            <div style="margin-left: 10px">
              <a-radio-group
                  v-model="dataForm.template_content.prescribed_medication.is_use_load_dose"
                  :options="questisUse" />
            </div>
          </div>
          <div style="display: flex;margin-top: 10px">
            <div style="width: 100px;text-align: right">使用剂量范围:</div>
            <div style="margin-left: 10px;display: flex">
              <a-input
                  placeholder="请输入使用剂量范围"
                  v-model="partition.Using_the_total.small"
                  style="width: 148px"/>
              <span>-</span>
              <a-input
                  placeholder="请输入使用剂量范围"
                  v-model="partition.Using_the_total.big"
                  style="width: 148px"/>
            </div>
          </div>
          <div style="display: flex;margin-top: 10px">
            <div style="width: 100px;text-align: right">使用总量:</div>
            <div style="margin-left: 10px">
              <a-input
                  v-model="dataForm.template_content.prescribed_medication.total"
                  style="width: 300px"
                  placeholder="请输入使用总量(mg)"
              />
            </div>
          </div>
        </div>
        <div style="width: 100%;margin-top: 20px">
          <div style="margin-left: 80px;">
            <div>合并用药情况 :</div>
            <a-checkbox-group
                v-model="dataForm.template_content.progress_note.combination_medication"
                style="width: 350px;margin-left: 80px"
                @change="checkboxChange">
              <a-row>
                <a-col :span="24" style="margin-top: 10px;">
                  <a-checkbox value="乌拉地尔">
                    乌拉地尔
                  </a-checkbox>
                </a-col>
                <a-col :span="24" style="margin-top: 10px;">
                  <a-checkbox value="硝普钠">
                    硝普钠
                  </a-checkbox>
                </a-col>
                <a-col :span="24" style="margin-top: 10px;">
                  <a-checkbox value="地尔硫卓">
                    D地尔硫卓
                  </a-checkbox>
                </a-col>
                <a-col :span="24" style="margin-top: 10px;">
                  <a-checkbox value="右美托咪定 ">
                    右美托咪定
                  </a-checkbox>
                </a-col>
                <a-col :span="24" style="margin-top: 10px;">
                  <a-checkbox value="米力农 ">
                    米力农
                  </a-checkbox>
                </a-col>
                <a-col :span="24" style="margin-top: 10px;">
                  <a-checkbox value="左西孟旦 ">
                    左西孟旦
                  </a-checkbox>
                </a-col>
              </a-row>
            </a-checkbox-group>
          </div>
        </div>
        <div style="display: flex;width: 100%;margin-top: 20px">
          <div style="margin-left: 30px;display: flex">
            病程记录+化验结果信息
          </div>
        </div>
        <div style="display: flex;margin-top: 10px;margin-left: 30px">
          <div style="width: 120px;text-align: right">患者转归:</div>
          <div style="margin-left: 10px">
            <a-radio-group
                v-model="dataForm.template_content.progress_note.patient_transition"
                :options="questHuan" />
          </div>
        </div>
        <div style="margin-top: 10px;margin-left: 90px">
          <div>
            心率HR(次/分)
          </div>
          <div style="display: flex;margin-top: 10px">
            <a-input
                v-model="partition.heart.start"
                placeholder="开始用药" style="width: 145px"/>
            <a-input
                v-model="partition.heart.end"
                placeholder="结束用药" style="width: 145px;margin-left: 30px"/>
          </div>
        </div>
        <div style="margin-top: 10px;margin-left: 90px">
          <div>
            血压BP(mmHg)
          </div>
          <div style="display: flex;margin-top: 10px">
            <a-input
                v-model="partition.str_BP.start"
                placeholder="开始用药" style="width: 145px"/>
            <a-input
                v-model="partition.str_BP.end"
                placeholder="结束用药" style="width: 145px;margin-left: 30px"/>
          </div>
        </div>
        <div style="margin-top: 10px;margin-left: 90px">
          <div>
            体温T(℃)
          </div>
          <div style="display: flex;margin-top: 10px">
            <a-input
                v-model="partition.str_t.start"
                placeholder="开始用药" style="width: 145px"/>
            <a-input
                v-model="partition.str_t.end"
                placeholder="结束用药" style="width: 145px;margin-left: 30px"/>
          </div>
        </div>
        <div style="margin-top: 10px;margin-left: 90px">
          <div>
            血肌酐（umol/L)
          </div>
          <div style="display: flex;margin-top: 10px">
            <a-input
                v-model="partition.umol.start"
                placeholder="开始用药" style="width: 145px"/>
            <a-input
                v-model="partition.umol.end"
                placeholder="结束用药" style="width: 145px;margin-left: 30px"/>
          </div>
        </div>
        <div style="margin-top: 10px;margin-left: 90px">
          <div>
            BNP/NT-ProBNP
          </div>
          <div style="display: flex;margin-top: 10px">
            <a-input
                v-model="partition.ProBNP.start"
                placeholder="开始用药" style="width: 145px"/>
            <a-input
                v-model="partition.ProBNP.end"
                placeholder="结束用药" style="width: 145px;margin-left: 30px"/>
          </div>
        </div>
        <div style="margin-top: 10px;margin-left: 90px">
          <div>
            肌钙蛋白I
          </div>
          <div style="display: flex;margin-top: 10px">
            <a-input
                v-model="partition.str_i.start"
                placeholder="开始用药" style="width: 145px"/>
            <a-input
                v-model="partition.str_i.end"
                placeholder="结束用药" style="width: 145px;margin-left: 30px"/>
          </div>
        </div>
        <div style="margin-top: 10px;margin-left: 90px;display: flex">
          <div>
            不良反应:
          </div>
          <div style="margin-left: 10px">
            <a-radio-group
                v-model="dataForm.template_content.progress_note.is_adverse_reaction"
                :options="questReaction" />
          </div>
        </div>
        <div style="margin-top: 10px;margin-left: 90px;margin-bottom: 20px">
          <a-input
              v-model="dataForm.template_content.progress_note.detail"
              placeholder="请描述具体情况及措施"
              style="width: 320px"/>
        </div>
      </div>
    </div>
    <div style="height: 50px"></div>
    <div class="footer-div">
      <div class="footer-div-btn">
        <a-button @click="returnBtn">返回上一层</a-button>
        <a-button
            v-show="!passValue"
            style="margin-left: 10px"
            @click="unPassBtn">驳回</a-button>
        <a-button
            v-show="!passValue"
            @click="passBtn"
            style="margin-left: 10px"
            type="primary">通过</a-button>
      </div>
    </div>
<!------------------------------------------------对话框----------------------------------------------------->
    <a-modal
        width="700px"
        v-model="visible"
        title="审批">
      <template slot="footer">
        <a-button @click="quxiaoModel">
          取消
        </a-button>
        <a-button type="primary"  @click="overruleModel">
          确定
        </a-button>
      </template>
      <div style="width: 100%;display: flex">
        <div style="width:20%;text-align: right;font-weight: bold;color: black">驳回原因 :</div>
        <a-checkbox-group
            v-model="checkboxVal"
            @change="checkbox_group_onChange"
            style="margin-left: 10px;width: 600px;">
          <a-row>
            <a-col style="margin-bottom: 8px" :span="24" v-for="(overRule,index) in overRules">
              <a-checkbox
                  :value="overRule">
                {{overRule}}
              </a-checkbox>
            </a-col>
          </a-row>
        </a-checkbox-group>
      </div>
      <a-textarea
          style="width:500px;margin-left: 120px;margin-top: 10px;"
          v-model="overruleValue"
          placeholder="请输入其他驳回原因"
          :auto-size="{ minRows: 6, maxRows: 10 }"
      />
    </a-modal>
  </div>
</template>

<script>

import moment from "moment";
import {
  getCaseMessage,
  getCaseMessage_course,
  putCaseGo,
  putSex,
  TemplateIdRepeatHospitalNumber
} from "../../../service/case_api";

export default {
  data(){
    return{
      lenghtImage:'',
      overRules:[],
      checkboxVal:[],
      // passBtnVal:true,
      dateFormatList:'YYYY-MM-DD',
      router_id:'',
      sexData:'',
      caseDetail:[],
      value:1,
      overruleValue:'',
      valueAge:20,
      visible:false,
      srcTest:'https://questionnaire.yunicu.com/#/esmolol/m',
      questSex : [
        { label: '男', value: 1 },
        { label: '女', value: 2 },
      ],
      questAnotherHospital : [
        { label: '否', value: 0 },
        { label: '是', value: 1 },
      ],
      //转院
      questBlood : [
        { label: '否', value: 0 },
        { label: '是', value: 1 },
      ],
      questChest  : [
        { label: '否', value: 0 },
        { label: '是', value: 1 },
      ],
      questOPS  : [
        { label: '否', value: 0 },
        { label: '是', value: 1 },
      ],
      questReaction  : [
        { label: '否', value: 0 },
        { label: '是', value: 1 },
      ],
      questuse  : [
        { label: '持续不间断使用', value: 0 },
        { label: '持续间断使用', value: 1 },
      ],
      questisUse  : [
        { label: '否', value: 0 },
        { label: '是', value: 1 },
      ],
      questHuan  : [
        { label: '好转', value: 0 },
        { label: '未愈', value: 1 },
        { label: '死亡', value: 2 },
      ],
      SexPerson : [
        { label: '男', value: 1 },
        { label: '女', value: 2 },
      ],

      //审核状态
      state:'',
      ageData:'',
      //  模拟数据
      dataForm: {
        "id": null,
        "memberName": "",
        "memberHospitalName": "",
        "patientSex": null,
        "patientAge": "",
        "audit_status": "",
        "reject_reason":'',
        "template_content": {
          "prescribed_medication": {
            "is_use_load_dose": null,
            "start_time": "",
            "total": "",
            "dose_range": "",
            "method": null,
            "end_time": "",
            "title": "",
            "image_list": [
            ],
            "combination_medication": [
            ]
          },
          "resident_admit_note": {
            "diagnosis_admission": [
            ],
            "sympotms_signs": "",
            "hypertension_hostory": null,
            "is_operation": null,
            "sex": null,
            "hr": "",
            "office": "",
            "previous_hostory": "",
            "title": "",
            "is_referral": null,
            "breathe": "",
            "bp": "",
            "patient_number": "",
            "operation_time": "",
            "other_admission": "",
            "pulse": "",
            "temperature": "",
            "admission_time": "",
            "is_chest_abdomen_back_pain": null,
            "age": "",
            "height": "",
            "image_list": [
            ]
          },
          "progress_note": {
            "is_use_load_dose": null,
            "patient_transition": null,
            "method": null,
            "Bnp": "",
            "serum_creatinine": "",
            "end_time": "",
            "hr": "",
            "medication_image_list": [
            ],
            "title": "",
            "bp": "",
            "TnI": "",
            "combination_medication": [
            ],
            "start_time": "2022-04-20",
            "total": "",
            "dose_range": "",
            "is_adverse_reaction": null,
            "temperature": "",
            "detail": "",
            "image_list": [
            ]
          }
        },
        "created_time": ""
      },
      //分割字符串
      partition:{
        //使用总量
        Using_the_total:{
          small:'',
          big:'',
        },
        //心率HR(次/分
        heart:{
          start:'',
          end:'',
        },
        //bp
        str_BP:{
          start:'',
          end:'',
        },
        //体温T(℃)
        str_t:{
          start:'',
          end:'',
        },
        //血肌酐（umol/L)
        umol:{
          start:'',
          end:'',
        },
        //BNP/NT-ProBNP
        ProBNP:{
          start:'',
          end:'',
        },
        //肌钙蛋白I
        str_i:{
          start:'',
          end:'',
        },
      }
    }
  },
  created() {
    this.getCase()
    this.getCaseCourse()
  },
  mounted() {
  },
  computed:{
    passValue(){
      return this.$store.state.passBtn
    },
  },
  methods:{
    //获取数据
    async getCase() {
      let id = this.$route.query.id
      const response = await getCaseMessage(id)
      if(response.code === 0){
        this.dataForm = response.data
        this.state = response.data.audit_status
        this.overruleValue = response.data.reject_reason
        await this.partitionData()
        // if(this.state === 'PASSED' && this.passValue === ''){
        //   this.passBtnVal = false
        // }
      }else {
        this.$message.warning(response.message)
      }
      this.lenghtImage = this.dataForm.coding_image_list.length
    },
    //获取驳回原因
    async getCaseCourse() {
      let id = this.$route.query.templeteId
      const response = await getCaseMessage_course(id)
      if(response.code === 0){
        this.overRules = response.data.remark
      }else {
        this.$message.warning(response.message)
      }
    },
    //分割数据
    partitionData(){
      const form = this.dataForm
      //使用剂量范围
      const str_part = form.template_content.prescribed_medication.dose_range
      this.partition.Using_the_total.small = str_part.split('-')[0];
      this.partition.Using_the_total.big = str_part.split('-')[1];
      //心率HR(次/分
      const str_hr = form.template_content.progress_note.hr
      this.partition.heart.start = str_hr.split('-')[0];
      this.partition.heart.end = str_hr.split('-')[1];
      //血压BP(mmHg)
      const str_BP = form.template_content.progress_note.bp
      this.partition.str_BP.start = str_BP.split('-')[0];
      this.partition.str_BP.end = str_BP.split('-')[1];
      //体温T(℃)
      const str_t = form.template_content.progress_note.temperature
      this.partition.str_t.start = str_t.split('-')[0];
      this.partition.str_t.end = str_t.split('-')[1];

      //血肌酐（umol/L)
      const str_umol = form.template_content.progress_note.serum_creatinine
      this.partition.umol.start = str_umol.split('-')[0];
      this.partition.umol.end = str_umol.split('-')[1];
      //BNP/NT-ProBNP
      const str_ProBNP = form.template_content.progress_note.Bnp
      this.partition.ProBNP.start = str_ProBNP.split('-')[0];
      this.partition.ProBNP.end = str_ProBNP.split('-')[1];
      //肌钙蛋白I
      const str_i = form.template_content.progress_note.TnI
      this.partition.str_i.start = str_i.split('-')[0];
      this.partition.str_i.end = str_i.split('-')[1];
    },
    //修改
    async putCase() {
      let id = this.$route.query.id
      let data = this.dataForm
      const response = await putCaseGo(id,data)
      if(response.code === 0){
        this.$message.success('修改成功')
      }else {
        this.$message.warning(response.message)
      }
    },
    async passBtn() {
      let form = this.dataForm
      //合并输入框的值
      //使用剂量范围
      form.template_content.prescribed_medication.dose_range = this.partition.Using_the_total.small + '-' + this.partition.Using_the_total.big
      //心率HR(次/分
      form.template_content.progress_note.hr = this.partition.heart.start + '-' + this.partition.heart.end
      //血压BP(mmHg)
      form.template_content.progress_note.bp = this.partition.str_BP.start + '-' + this.partition.str_BP.end
      //体温T(℃)
      form.template_content.progress_note.temperature = this.partition.str_t.start + '-' + this.partition.str_t.end
      //血肌酐（umol/L)
      form.template_content.progress_note.serum_creatinine = this.partition.umol.start + '-' + this.partition.umol.end
      //BNP/NT-ProBNP
      form.template_content.progress_note.Bnp = this.partition.ProBNP.start + '-' + this.partition.ProBNP.end
      //肌钙蛋白I
      form.template_content.progress_note.TnI = this.partition.str_i.start + '-' + this.partition.str_i.end

      //通过
      form.audit_status = "PASSED"
      let id = this.$route.query.id
      let templeteIdY = this.$route.query.templeteId
      const reponse = await putCaseGo(id,form,templeteIdY)
      if (reponse.code === 0) {
        this.$message.success('已通过！' + reponse.message)
      //刷新
        await this.getCase()
      } else {
        this.$message.error("失败" + reponse.message)
      }
    },

    //性别
    onChange(){

    },
    //返回上一层
    returnBtn() {
      window.history.back()
    },
    unPassBtn(){
      this.visible = true
    },
    //修改性别和年龄
    async putSex(sexData,ageData){
      const id = this.$route.query.id
      const data = {
        "patientSex":parseInt(sexData),
        "birthday":ageData
      }
      const reponce = await putSex(id,data)
      if(reponce.code === 0){
        this.$message.success('修改成功！'+reponce.message)
        await this.getCase()
      }else {
        this.$message.warning(reponce.message)
      }
    },
    // 保存患者信息
    preservation(){
      const sex = parseInt(this.dataForm.patientSex)
      const age = this.ageData
      if(sex!== '' && sex !== null && sex !== undefined ||
          age!== '' && age !== null && age !== undefined){
        switch (sex){
          case 1:
            this.putSex(1,age)
            break;
          case 2:
            this.putSex(2,age)
            break;
          default:
            break;
        }
      }
    },
    //出生日期
    ageChange(date){
      const dataTime = moment(date).format('YYYY-MM-DD')
      this.ageData = dataTime
    },
    //入院选择
    hospitalizedChange(date){
      this.dataForm.template_content.resident_admit_note.admission_time = moment(date).format('YYYY-MM-DD')
    },
    operationChange(date){
      this.dataForm.template_content.resident_admit_note.operation_time = moment(date).format('YYYY-MM-DD')
    },
    startChange(date){
      this.dataForm.template_content.prescribed_medication.start_time = moment(date).format('YYYY-MM-DD')
    },
    endChange(date){
      this.dataForm.template_content.prescribed_medication.end_time = moment(date).format('YYYY-MM-DD')
    },
    checkboxChange(checkedValues){
    },
    useChange(){

    },
    finishChange(){

    },
    quxiaoModel() {
      this.visible = false;
    },
    checkbox_group_onChange(){
      this.overruleValue = this.checkboxVal.join(';')
    },
    //驳回确定按钮
    async overruleModel() {
      let form = this.dataForm
      //合并输入框的值
      //使用剂量范围
      form.template_content.prescribed_medication.dose_range = this.partition.Using_the_total.small + '-' + this.partition.Using_the_total.big
      //心率HR(次/分
      form.template_content.progress_note.hr = this.partition.heart.start + '-' + this.partition.heart.end
      //血压BP(mmHg)
      form.template_content.progress_note.bp = this.partition.str_BP.start + '-' + this.partition.str_BP.end
      //体温T(℃)
      form.template_content.progress_note.temperature = this.partition.str_t.start + '-' + this.partition.str_t.end
      //血肌酐（umol/L)
      form.template_content.progress_note.serum_creatinine = this.partition.umol.start + '-' + this.partition.umol.end
      //BNP/NT-ProBNP
      form.template_content.progress_note.Bnp = this.partition.ProBNP.start + '-' + this.partition.ProBNP.end
      //肌钙蛋白I
      form.template_content.progress_note.TnI = this.partition.str_i.start + '-' + this.partition.str_i.end

      let id = this.$route.query.id
      let templeteIdY = this.$route.query.templeteId
      form.audit_status = "DENIED"
      form.reject_reason = this.overruleValue
      const reponse = await putCaseGo(id, form,templeteIdY)
      if (reponse.code === 0) {
        this.$message.success('已驳回！' + reponse.message)
      } else {
        this.$message.error("失败，请联系管理员！！" + reponse.message)
      }
      this.visible = false
    },
    //  图片刷新
    refreshBtn(){
      this.getCase()
    },
    //  打码
    captchaBtn(){
      const userData = JSON.parse(window.localStorage.getItem("user_info"))
      // window.open(`${this.adminLink}`+ '?id=' + row.id + '&user_id=' + userData.id)
      let id = this.$route.query.id
      this.$router.push({ path: '/caseCode',query:{id:id,userId:userData.id,userName:userData.user_name}})
    },
    async examineBtn(id,member) {
      const reponse = await TemplateIdRepeatHospitalNumber(id,member)
      if (reponse.code === 0) {
        this.$message.success(reponse.message)
      } else {
        this.$message.error("失败，请联系管理员！！" + reponse.message)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.review-title{
  font-size: 22px;
  font-weight: bold;
  color: black;
  height:100px;
  line-height: 80px;
  padding-left: 30px;
  border-bottom: 10px solid #f5f5f5;
}
.review-doc{
  height:120px;
  line-height: 50px;
  border-bottom: 10px solid #f5f5f5;
  color: black;
  .review-doc-title{
    font-size: 15px;
    color: black;
    font-weight: bold;
    padding-left: 30px;
    border-bottom: 2px solid #f5f5f5;
  }
  .review-doc-content{
    width: 100%;
    display: flex;
    color: black;
    .review-doc-content-font{
      width: 30%;
      padding-left: 30px;
      color: black;
    }
  }
}

.review-huan{
  height:150px;
  line-height: 50px;
  border-bottom: 10px solid #f5f5f5;
  color: black;
  .review-huan-title{
    padding-left: 30px;
    border-bottom: 2px solid #f5f5f5;
    display: flex;
    color: black;
    .review-huan-title-huanzhe{
      font-size: 15px;
      color: black;
      font-weight: bold;
      width: 85%;
    }
  }
  .review-huan-content{
    width: 100%;
    display: flex;
    color: black;
    .review-huan-content-font{
      width: 30%;
      padding-left: 30px;
      padding-top: 15px;
      display: flex;
      color: black;
    }
    .review-huan-content-font-two{
      display: flex;
      width: 30%;
      padding-left: 30px;
      padding-top: 15px;
      color: black;
    }
  }
}
.review-content{
  height: auto;
  display: flex;
  //border-bottom: 10px solid #f5f5f5;
  color: black;
  .review-content-left{
    width: 50%;
    //height: 100%;
    min-height: 2000px;
    color: black;
    .review-content-left-title{
      font-size: 16px;
      height: 60px;
      line-height: 60px;
      color: black;
      font-weight: bold;
      padding-left: 30px;
      border-bottom: 2px solid #f5f5f5;
    }
    .review-content-left-font{
      font-weight: bold;
      padding-left: 30px;
      color: black;
      margin-bottom: 15px;
    }
  }
  .review-content-right{
    width: 50%;
    height: auto;
    color: black;
    border-left: 10px solid #f5f5f5;
    .review-content-right-title{
      font-size: 16px;
      height: 60px;
      line-height: 60px;
      color: black;
      font-weight: bold;
      padding-left: 30px;
      border-bottom: 2px solid #f5f5f5;
    }
    .review-content-right-title-title{
      font-weight: bold;
      padding-left: 30px;
      margin-top: 30px;
      color: black;
    }
    .review-content-right-person{
      display: flex;
      margin-bottom: 15px;
      width: 100%;
      margin-left:80px;
      color: black;
      .review-content-right-person-name{
        width: 50%;
        margin-left: 5%;
        color: black;
      }
      .review-content-right-person-sex{
        width: 50%;
      }
    }
    .toBeMessage{
      margin-bottom: 15px;
      display: flex;
      width: 100%;
      .font{
        width: 50%;
        color: black;
      }
      .selectBtn{
        width: 50%;
        color: black;
      }
    }
    .betoMessage{
      margin-bottom: 15px;
      display: flex;
      width: 100%;
      .font{
        width: 150px;
        text-align: right;
        color: black;
      }
      .inputShu{
        width: 60%;
        margin-left: 5px;
        color: black;
      }
    }
    .mazui{
      margin-bottom: 15px;
      width: 100%;
      .font{
        margin-left: 5%;
        color: black;
      }
    }
    .mazui-input{
      margin-bottom: 15px;
      display: flex;
      width: 100%;
      color: black;
      .mazui-input-one{
        width: 24%;
        text-align: center;
        color: black;
      }
    }
    .inputWitch{
      width: 60%;
      color: black;
    }
  }
}
.footer-div{
  width: 100%;
  height: 50px;
  background-color: #f0f2f5;
  position: fixed;
  bottom: 50px;
  border-bottom: 1px solid #fff;
  .footer-div-btn{
    display: flex;justify-content: right;
    width: 82%;
    margin-top: 8px;
  }
}
</style>